html {
  scroll-behavior: smooth;
}
body{
  height: 200%;
  background-color: black;
}
p{
  color: darkgray;
  font-family:'Courier New', Courier, monospace;
}

.wrapper{
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 160px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color:gray; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  /* padding-top: 20px; */

}

.top-buffer { 
  margin: 250px;
 
  display: flex;
  
}
.about{

  margin-top: 0px;

}
.projectCards{
  
  color: teal;
}
.arrow{
  margin-top: 200px;
}
.contact{
  margin-top: 400px;
}
.container{
  margin-bottom: 200px;
}
.start{

  text-align: center;
  color: darkgray;
  font-style: oblique;
  font-family: 'Courier New', Courier, monospace;
  font-size: 30px;
  
}
.switch{
  font-family: 'Courier New', Courier, monospace;
  color: greenyellow;
  font-size: 22px;
}

.myFooter{
  align-items: center;
   
}

.list-inline-item{
  color: white;
  margin-left: 40px; 
 
}

.icon:hover{
  color-adjust:  darkgray;
  transform: scale(1.5);
  
}
.nav-item > .active{
  color: bisque;
  
}